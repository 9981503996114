import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SavedQueryId } from "@sp-crm/core";
import {
    AdvancedSearchRequest,
    AdvancedSearchRequestType,
    GetSavedQueriesQuery,
} from "generated/graphql";
import { SetStateAction } from "react";

interface CustomReportsState {
    newReportInput: AdvancedSearchRequest;
    existingReportInput: {
        [key: SavedQueryId]: AdvancedSearchRequest;
    };
}

const initialState: CustomReportsState = {
    newReportInput: null,
    existingReportInput: {},
};

interface SetExistingReportInputPayload {
    reportId: SavedQueryId;
    input: SetStateAction<AdvancedSearchRequest> | AdvancedSearchRequest;
}

export const customReportsSlice = createSlice({
    name: "customReports",
    initialState,
    reducers: {
        setNewReportInput: (state, action: PayloadAction<AdvancedSearchRequest>) => {
            state.newReportInput = action.payload;
        },
        initialReportView: (
            state,
            action: PayloadAction<
                GetSavedQueriesQuery["getSavedQueries"]["savedQueries"][0]
            >,
        ) => {
            if (!state.existingReportInput[action.payload.id]) {
                state.existingReportInput = {
                    [action.payload.id]: {
                        condition: action.payload.condition,
                        sort: action.payload.sort,
                        entityType: action.payload.entityType,
                        select: action.payload.select,
                        summaries: action.payload.summaries,
                        type: AdvancedSearchRequestType.Explicit,
                    },
                };
            }
        },
        setExistingReportInput: (
            state,
            action: PayloadAction<SetExistingReportInputPayload>,
        ) => {
            const { reportId, input } = action.payload;

            if (typeof input === "function") {
                state.existingReportInput[reportId] = input(
                    state.existingReportInput[reportId],
                );
            } else {
                state.existingReportInput[reportId] = input;
            }
        },
    },
});
